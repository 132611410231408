export default {
  "LoginPageHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "ClinicLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à la clinique"])}
  },
  "CorporateFooter": {
    "PoweredByText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Optimisé par ", _interpolate(_named("brandName"))])},
    "CustomerSupportButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service à la clientèle"])},
    "TermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
    "PrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
  },
  "ContactSupportModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le soutien"])}
  },
  "LoginForm": {
    "EmailFieldDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse courriel"])},
    "PasswordFieldDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "LoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "SignUpButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’inscrire"])},
    "ForgotPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne connais pas mon mot de passe"])},
    "UnconfirmedEmailErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte n'a pas été confirmé"])},
    "ValidationErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors de la validation de votre adresse courriel ou de votre mot de passe"])},
    "LoginErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande n’a pu être traitée. Veuillez contacter le service d’assistance si le problème persiste."])},
    "SessionExpiredErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée"])},
    "SessionExpiredErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre session a expiré, veuillez vous reconnecter"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion"])},
    "RecordNotFoundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte patient introuvable."])}
  },
  "LoginPage": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération des données pharmaceutiques..."])},
    "LoginPageGreetingCmd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur CloudMD. Pour prendre rendez-vous et consulter un médecin en ligne, veuillez vous connecter ou créer un compte."])},
    "LoginPageGreetingMha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur MyHealthAccess. Pour vous connecter à vos prestataires de soins de santé et gérer vos rendez-vous, veuillez vous connecter ou créer un compte"])}
  },
  "LoginConfirm": {
    "appName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName"))])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la connexion"])},
    "SentConfirmationEmailMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous avons envoyé des instructions de confirmation par courriel à l'adresse suivante : ", _interpolate(_named("patientEmail")), "."])},
    "MustConfirmEmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez confirmer votre adresse courriel avant de pouvoir vous connecter."])},
    "ConfirmEmailInstructionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez l'adresse courriel que vous avez utilisée pour vous connecter. Pour avoir la confirmation de votre adresse courriel, nous vous enverrons des instructions"])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel"])},
    "SendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "NotificationErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "FailedToSendConfirmationEmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi du courriel de confirmation impossible. Veuillez réessayer ou contacter le service d'assistance pour obtenir de l'aide"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "NotificationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
    "AccountConfirmedSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte ", _interpolate(_named("appName")), " a été confirmé"])},
    "ProblemConfirmingEmailErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La confirmation de votre adresse courriel rencontre un problème. Veuillez réessayer ou contacter le service d'assistance pour obtenir de l'aide"])},
    "EmailAlreadyConfirmedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse courriel a déjà été confirmée. Veuillez vous inscrire."])}
  },
  "LoginReset": {
    "appName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName"))])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "ResetPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un nouveau mot de passe pour votre compte"])},
    "NewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe (8 caractères min.)"])},
    "ConfirmPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "ResetPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mon mot de passe"])},
    "PasswordResetSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de votre mot de passe réussie"])},
    "PasswordResetFailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de votre mot de passe impossible"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours..."])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réssayer"])},
    "PasswordResetSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous allez être connecté à votre compte ", _interpolate(_named("appName")), " dans un instant..."])},
    "GenericPasswordResetFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu. Pour plus d'aide, veuillez réessayer ou retourner à l'écran de connexion."])},
    "TokenExpiredFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre jeton de réinitialisation de mot de passe a expiré. Cliquez ci-dessous pour en demander un autre."])}
  },
  "LoginForgot": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "ForgotPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour réinitialiser votre mot de passe, veuillez saisir votre adresse courriel ci-dessous."])},
    "EmailFieldText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse courriel"])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "ReturnToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'écran de connexion"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel a été envoyé"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réinitialisation de votre mot de passe a rencontré un problème. Pour obtenir de l'aide, veuillez contacter le service clientèle"])},
    "EmailSentSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un courriel contenant des instructions expliquant comment réinitialiser votre mot de passe a été envoyé. Si vous ne recevez pas ce courriel, vérifiez votre courrier indésirable ou réessayez."])}
  },
  "HeaderBarForgot": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])}
  },
  "WhitelabelMore": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur CloudMD"])}
  },
  "WhitelabelLogin": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer, veuillez vous connecter ou créer un compte."])},
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'enregistrer pour consulter un médecin"])}
  },
  "WhitelabelHome": {
    "SeeADoctorButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter un médecin maintenant"])},
    "OperationHoursButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d'ouverture"])},
    "WelcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin va vous voir maintenant."])},
    "WelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiez de soins médicaux virtuels en fonction de votre emploi du temps et depuis n'importe quel appareil, y compris votre téléphone, votre ordinateur de bureau et votre tablette. Entrez en contact instantanément avec un médecin canadien agréé!"])}
  },
  "WhitelabelCareCards": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi les patients aiment les soins virtuels"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez un médecin quand cela vous convient. À tout moment, où que vous soyez. Prendre une demi-journée de congé pour, une fois au cabinet médical, attendre une heure pour une consultation de 10 minutes devient inutile. Le médecin va vous voir maintenant."])},
    "SeeADoctorCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter un médecin"])},
    "SeeADoctorCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratuitement"])},
    "SeeADoctorCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible sans frais pour les résidents de la Colombie-Britannique et de l'Ontario."])},
    "TimeOffCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inutile de prendre"])},
    "TimeOffCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un congé"])},
    "TimeOffCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès aux médecins le soir et le week-end."])},
    "QualifiedDoctorsCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins canadiens"])},
    "QualifiedDoctorsCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qualifiés."])},
    "QualifiedDoctorsCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordonnances envoyées directement par votre médecin à votre pharmacie locale."])},
    "ConnectFromCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à partir de"])},
    "ConnectFromCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'importe quel appareil"])},
    "ConnectFromCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface simple et facile à utiliser."])}
  },
  "HowCanWeHelpCard": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment CloudMD peut-il vous aider?"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici les types de consultations que les médecins peuvent effectuer par télémédecine :"])},
    "GeneralMedicalAdvice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils médicaux généraux"])},
    "Referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orientation vers des spécialistes"])},
    "SinusInfections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infections des sinus"])},
    "BirthControlPrescriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescription de la pilule contraceptive"])},
    "Prescriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordonnances nouvelles et renouvelées"])},
    "LabResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examen des résultats des tests/analyses en laboratoire"])},
    "SkinInflammations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éruptions cutanées et inflammation de la peau"])},
    "Utis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infection des voies urinaires (IVU)"])},
    "SickNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêts de travail"])},
    "Colds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toux ou rhume"])},
    "MentalHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultations pour des problèmes de santé mentale"])},
    "NutritionConcerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime alimentaire et problèmes concernant la nutrition"])}
  },
  "VirtualCareBanner": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin va vous voir maintenant."])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiez de soins médicaux virtuels en fonction de votre emploi du temps et depuis n'importe quel appareil, y compris votre téléphone, votre ordinateur de bureau et votre tablette. Entrez en contact instantanément avec un médecin canadien agréé!"])}
  },
  "AppDownload": {
    "OrgHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez l'application dès aujourd'hui"])},
    "DefaultHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez l'application!"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restez connecté à la maison ou depuis votre appareil mobile. Disponible dès maintenant sur l'AppStore et Google Play. L'accès aux soins quand vous en avez besoin. CloudMD accepte toujours de nouveaux patients."])}
  },
  "OnDemandBookingReason": {
    "reasonPromptSinglePharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir vous réserver une consultation médicale, nous devons informer le médecin de ce dont vous souhaitez discuter. Veuillez saisir le motif de votre rendez-vous médical dans le champ de texte ci-dessous."])},
    "reasonPromptMultiPharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir vous réserver une consultation médicale, nous devons informer le médecin de ce dont vous souhaitez discuter. Veuillez saisir le motif de votre rendez-vous médical dans le champ de texte ci-dessous. Sélectionnez également votre pharmacie préférée."])},
    "bookingLocationSelectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une pharmacie"])},
    "TitleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi voulez-vous consulter le médecin aujourd'hui?"])},
    "AppointmentReasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez ici la raison pour laquelle vous souhaitez consulter le médecin"])},
    "BookAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver votre consultation"])},
    "BookingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de réservation"])},
    "DuplicateBookingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation en double"])},
    "DuplicateBookingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un rendez-vous dans la file d'attente. Le rejoindre?"])},
    "RejoinButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "FullQueueErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilités dans la file d'attente"])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réssayer"])},
    "ClosedClinicErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé"])},
    "ClosedClinicErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clinique est fermée."])},
    "SeeClinicHoursButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les heures d'ouverture de la clinique"])},
    "BookNewAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle réservation"])},
    "AppointmentAlreadyBookedErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez récemment pris un rendez-vous, le ", _interpolate(_named("appointmentDate")), ". Souhaitez-vous le rejoindre?"])}
  },
  "OnDemandBookingHoursModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d'ouverture"])}
  },
  "OnDemandBookingRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection en cours…"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant le chargement de la page"])},
    "NoOrgErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation introuvable"])},
    "HomePageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])}
  },
  "SignupPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’inscrire"])},
    "BackToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page de connexion"])},
    "SignupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de réserver votre première consultation, remplissez le formulaire ci-dessous."])},
    "WhitelabelSignupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant la réservation de votre première consultation, le médecin a besoin que vous remplissiez le formulaire ci-dessous."])}
  },
  "SignupHeaderBar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture d'un compte"])}
  },
  "SignupForm": {
    "clinicName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName"))])},
    "UseOfWebsiteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation du service et du site Web est soumise à "])},
    "Our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nos"])},
    "And": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
    "TermsOfUseLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
    "PrivacyPolicyLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "CreateAccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte"])},
    "IneligibleHealthCardErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de santé non éligible"])},
    "AccountExistsErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte existe déjà"])},
    "AccountExistsErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse courriel est déjà utilisée. Vous avez oublié votre mot de passe?"])},
    "ForgotPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "ValidationErrorMessageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de validation"])},
    "BackToSignupButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'inscription"])},
    "AccountCreationFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
    "AccountCreationFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de votre compte a rencontré un problème. Pour réessayer, appuyez sur le bouton Retour ci-dessous, ou, pour obtenir de l'aide, contactez le service d'assistance."])},
    "ToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vers la page de connexion"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patientez ", _interpolate(_named("patientFirstName"))])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous créons votre compte."])},
    "PartialAccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé, mais..."])},
    "AccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription terminée"])},
    "SentEmailConfirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un courriel contenant des instructions expliquant comment confirmer votre compte a été envoyé à ", _interpolate(_named("patientEmail")), "."])},
    "PartialAccountCreatedInstructionMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte a pu être créé et un courriel de confirmation a été envoyé à ", _interpolate(_named("patientEmail")), ", mais votre connexion à ", _interpolate(_named("clinicName")), " n'a pas encore pu être effectuée pour le moment. Après avoir confirmé votre compte, veuillez vérifier les informations relatives à votre compte ou contacter la clinique pour plus de détails."])}
  },
  "Geodata": {
    "Countries": {
      "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["États-Unis"])}
    },
    "Provinces": {
      "AB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alberta"])},
      "BC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie-Britannique"])},
      "MB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manitoba"])},
      "NB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau-Brunswick"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terre-Neuve-et-Labrador"])},
      "NS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle-Écosse"])},
      "NT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoires du Nord-Ouest"])},
      "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunavut"])},
      "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
      "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["île-du-Prince-Édouard"])},
      "QC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Québec"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saskatchewan"])},
      "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire du Yukon"])}
    },
    "States": {
      "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alabama"])},
      "AK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alaska"])},
      "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arizona"])},
      "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkansas"])},
      "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Californie"])},
      "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colorado"])},
      "CT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecticut"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delaware"])},
      "DC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District de Columbia"])},
      "FL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floride"])},
      "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Géorgie"])},
      "HI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hawaï"])},
      "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idaho"])},
      "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illinois"])},
      "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiana"])},
      "IA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iowa"])},
      "KS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansas"])},
      "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kentucky"])},
      "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Louisiane"])},
      "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maine"])},
      "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maryland"])},
      "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massachusetts"])},
      "MI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Michigan"])},
      "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minnesota"])},
      "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mississippi"])},
      "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missouri"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montana"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebraska"])},
      "NV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevada"])},
      "NH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Hampshire"])},
      "NJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Jersey"])},
      "NM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Mexique"])},
      "NY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New York"])},
      "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caroline du Nord"])},
      "ND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dakota du Nord"])},
      "OH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohio"])},
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oklahoma"])},
      "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oregon"])},
      "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pennsylvanie"])},
      "RI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhode Island"])},
      "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caroline du Sud"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dakota du Sud"])},
      "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennessee"])},
      "TX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texas"])},
      "UT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utah"])},
      "VT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermont"])},
      "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virginie"])},
      "WA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washington"])},
      "WV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virginie-Occidentale"])},
      "WI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisconsin"])},
      "WY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyoming"])},
      "AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forces armées de l'Amérique (sauf Canada)"])},
      "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique, Canada, Europe, Moyen-Orient"])},
      "AP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forces armées du Pacifique"])},
      "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa américaines"])},
      "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Mariannes du Nord"])},
      "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porto Rico"])},
      "UM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles mineures éloignées des États-Unis"])},
      "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Îles Vierges des États-Unis"])}
    }
  },
  "InputLayout": {
    "RequiredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requis"])}
  },
  "DateSelector": {
    "DayPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "MonthPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "YearPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])}
  },
  "SignupSpinner": {
    "FailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de votre compte a rencontré un problème. Pour réessayer, appuyez sur le bouton Retour ci-dessous, ou, pour obtenir de l'aide, contactez le service d'assistance."])},
    "BackToSignUpButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'inscription"])},
    "GenericClinicName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la clinique"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Patientez ", _interpolate(_named("patientName"))])},
    "PartialAccountCreationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé, mais..."])},
    "AccountCreationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription terminée"])},
    "PartialAccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte a pu être créé et un courriel de confirmation a été envoyé à ", _interpolate(_named("patientEmail")), ", mais votre connexion à ", _interpolate(_named("clinicName")), " n'a pas encore pu être effectuée pour le moment. Après avoir confirmé votre compte, veuillez vérifier les informations relatives à votre compte ou contacter la clinique pour plus de détails."])},
    "AccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un courriel contenant des instructions expliquant comment confirmer votre compte a été envoyé à ", _interpolate(_named("patientEmail")), "."])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte ", _interpolate(_named("appName")), " est en cours de création."])}
  },
  "NavigatorBookingLandingPage": {
    "NavigatorBookingInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand souhaitez-vous voir votre navigateur?"])},
    "TalkToNavigator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parler au navigateur maintenant"])},
    "BookAnAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver une consultation auprès d'un navigateur"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])}
  },
  "AqsAppointmentType": {
    "instructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, super. Quel est le type de consultations dont vous avez besoin?"])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rendez-vous"])},
    "LiveChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clavardage en direct"])},
    "VideoCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel vidéo"])},
    "ClinicClosedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé"])},
    "ClinicClosedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clinique est fermée."])},
    "ClinicClosedConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les heures d'ouverture de la clinique"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la réservation de votre consultation sur demande"])}
  },
  "LiveChatBooking": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une infirmière vous contactera sous peu."])}
  },
  "OnDemandBookingWaitingRoom": {
    "AppointmentBookedHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation a été réservée"])},
    "AppointmentBookedSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin sera bientôt avec vous."])},
    "QueuePositionTextPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes"])},
    "QueuePositionTextPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans la file d'attente"])},
    "NextInLineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes le prochain/la prochaine sur la liste"])},
    "CancelAppointmentInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En annulant votre rendez-vous, vous perdrez votre place dans la file d'attente."])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "CancelAppointmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation d'un rendez-vous"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment annuler votre rendez-vous?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion au serveur présente des problèmes. Veuillez rafraîchir la page"])},
    "ConnectionErrorAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir"])},
    "AppointmentCancelledMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce créneau de consultation n'est plus disponible"])},
    "Exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])}
  },
  "OnDemandExamRoom": {
    "YourAppointmentStartsSoonHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin sera bientôt avec vous"])},
    "YourAppointmentStartsSoonSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["merci de votre patience."])},
    "CancelAppointmentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En annulant votre rendez-vous, vous perdrez votre place dans la file d'attente."])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation Télésanté est terminée. Passez une bonne journée!"])},
    "GoToHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])},
    "CancelAppointmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation d'un rendez-vous"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment annuler votre rendez-vous?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
  },
  "BookingNextAvailability": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des rendez-vous disponibles..."])},
    "NextAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité suivante"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "ShowMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me montrer"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème de connexion à la clinique"])},
    "NoAppointmentsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rendez-vous disponible"])},
    "NoAppointmentsAvailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rendez-vous disponible trouvé pour vous. Il est possible que la clinique ait modifié son programme ou que vous ayez atteint le nombre de rendez-vous prévu par ses règles de réservation."])},
    "UnableToContactClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de contacter la clinique"])},
    "FindingAppointmentHelperText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une minute, ", _interpolate(_named("firstName")), ". Je cherche des rendez-vous disponibles pour vous."])},
    "NextAvailableAppointmentsHelperText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), ", voici les ", _interpolate(_named("numberOfAppointmentSlots")), " rendez-vous suivants disponibles :"])},
    "ShowProvidersAvailabilityInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cliquez ci-dessous pour voir toutes les disponibilités de ", _interpolate(_named("providerFullNameWithPrefixSuffix")), ", puis choisissez celle qui vous convient."])},
    "FindingProvidersLoadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous trouvons des fournisseurs de soins de santé."])},
    "SelectAnotherProviderButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un fournisseur de soins de santé différent"])},
    "GenericProvidersAvailabilityInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour voir toutes les disponibilités et choisir celle qui vous convient."])}
  },
  "BookingAppointmentTime": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une heure"])},
    "SelectTimeInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez sélectionner dans la liste des heures de rendez-vous disponibles le ", _interpolate(_named("selectedDate")), "."])},
    "Morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matin"])},
    "Afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après-midi"])},
    "Evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soirée"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des rendez-vous disponibles..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rendez-vous disponible"])},
    "ChangeDateButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la date du rendez-vous"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucune disponibilité n'a été trouvée pour le ", _interpolate(_named("selectedDate")), ". Cliquez ci-dessous pour sélectionner un autre jour."])}
  },
  "BookingCalendar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel jour?"])},
    "SelectDateInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un mois et un jour."])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des rendez-vous disponibles..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rendez-vous disponible"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun rendez-vous disponible trouvé pour vous. Il est possible que la clinique ait modifié son programme ou que vous ayez atteint le nombre de rendez-vous prévu par ses règles de réservation."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])}
  },
  "ContactClinicRouteButton": {
    "ButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter la clinique"])}
  },
  "CalendarDays": {
    "SundayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dim"])},
    "MondayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lun"])},
    "TuesdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
    "WednesdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer"])},
    "ThursdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu"])},
    "FridayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ven"])},
    "SaturdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sam"])}
  },
  "BookingReason": {
    "AddAReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une raison"])},
    "ReasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de votre rendez-vous..."])},
    "ReasonInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ok, nous avons presque fini. Nous devons simplement justifier la raison de votre rendez-vous auprès de ", _interpolate(_named("providerFullNameWithPrefixSuffix")), " en 80 caractères ou moins."])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
  },
  "BookingConfirm": {
    "ConfirmDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer les détails"])},
    "BookingConfirmationDetailsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous allons confirmer les détails de votre rendez-vous :"])},
    "BookingConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous réserver ce rendez-vous?"])},
    "ThatIsCorrectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, c'est correct"])},
    "StartOverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je voudrais recommencer à zéro"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression des renseignements sur les rendez-vous en cours…"])}
  },
  "BookingFinal": {
    "LoadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation de votre rendez-vous"])},
    "SuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour la confirmation de votre rendez-vous, un message devrait arriver dans un bref délai..."])},
    "FailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la réservation de votre rendez-vous en raison des erreurs suivantes:"])},
    "RescheduleAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprogrammer le rendez-vous"])},
    "BackToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
    "ViewAppointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mes rendez-vous"])},
    "BookAnotherAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre un autre rendez-vous"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "RegularSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y a-t-il autre chose que vous aimeriez faire aujourd'hui?"])},
    "SplashScreenSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passez une bonne journée."])},
    "RescheduleFailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La modification de votre rendez-vous a échoué en raison des erreurs suivantes:"])}
  },
  "TelehealthHeader": {
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "ExitAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter Rendez-vous"])},
    "ExitAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment mettre fin à ce rendez-vous?"])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "Decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])}
  },
  "TelehealthBase": {
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion à Télésanté"])},
    "ConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue empêchant la connexion à Télésanté."])},
    "IncomingCallAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel entrant"])},
    "IncomingCallAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("callDisplayName")), " appelle!"])},
    "DeclineButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "AcceptButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "PoorConnectionAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaise connexion détectée"])},
    "PoorConnectionAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une mauvaise connexions est à l'origine de la perte de qualité de l'appel."])},
    "MicAndCameraErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'accès au microphone et à la caméra"])},
    "AVPermissionDeniedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez refusé l'autorisation d'accès à votre microphone ou à votre caméra. Veuillez accorder l'autorisation et recharger cette page"])},
    "AVNoDevicesErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas réussi à trouver votre microphone ni votre caméra. Veuillez vous assurer qu'ils sont branchés et recharger cette page"])},
    "AVHardwareFaultErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous rencontrons des problèmes avec l'utilisation de votre microphone ou de votre caméra. Veuillez vous assurer qu'aucune autre application ne les utilise et recharger cette page"])},
    "GenericAVErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur du microphone et de la caméra"])},
    "ReloadButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger la page"])},
    "AppointmentAccessErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'accès à Rendez-vous"])},
    "AppointmentAccessErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("loggedInUserEmail")), " n'a pas accès à ce rendez-vous. Veuillez vérifier une nouvelle fois votre rappel et réessayer."])},
    "LogoutButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "ConnectionFailedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la tentative de connexion à la session de Télésanté. Veuillez réessayer."])},
    "FailedToSendDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’envoi du document a échoué"])}
  },
  "TelehealthPatient": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin est parti"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin a quitté l'appel. Si vous ne pensez pas qu'il va revenir, vous pouvez raccrocher à tout moment"])}
  },
  "VideoView": {
    "ChatButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clavarder"])}
  },
  "TelehealthTextChat": {
    "Exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "ChattingWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clavarder avec : ", _interpolate(_named("patientRemoteName"))])},
    "RoomEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le salon de discussion est vide"])},
    "LeaveChatTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter le clavardage?"])},
    "LeaveChatConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment quitter?"])},
    "Stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester"])},
    "Leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])}
  },
  "ChatView": {
    "MessagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "SendMessageTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "SendFileTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un fichier"])}
  },
  "AxiosResponseInterceptor": {
    "NetworkErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de réseau"])},
    "NetworkErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors de la connexion aux serveurs"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "RemoteConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la communication avec le serveur de la clinique"])}
  },
  "AlertService": {
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])}
  },
  "Notifier": {
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])}
  },
  "HomePageLinks": {
    "WelcomeBackMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue, ", _interpolate(_named("patientFirstName")), ". En quoi pouvons-nous vous aider?"])},
    "BookAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre un rendez-vous"])},
    "FindClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une clinique"])},
    "MyClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes cliniques"])},
    "MyMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes messages"])},
    "MyAppointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rendez-vous"])},
    "SearchHealthInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche d'informations sur la santé"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours…"])}
  },
  "HomePageHeaderBar": {
    "AccountHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "MyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "Dependents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépendants"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de vérification"])},
    "LogOutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "BackToAppText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Retour à ", _interpolate(_named("appName"))])}
  },
  "HomePage": {
    "TabBarAppointmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous"])},
    "TabBarAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "TabBarMessagesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])}
  },
  "HomeActionSheetButtons": {
    "AccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "DependentsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes personnes à charge"])},
    "SecurityButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
    "PrivacyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
    "VerificationCodeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de vérification"])},
    "LogOutButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "NotificationService": {
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter le service d'assistance si le problème persiste."])},
    "NoClinicsErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique connectée"])},
    "NoClinicsErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes connecté(e) à aucune clinique"])},
    "FindClinicButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une clinique"])}
  },
  "BannerNotificationFactory": {
    "PatientAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez un rendez-vous Télésanté à ", _interpolate(_named("appointmentStartDateTime"))])},
    "DependentAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dependentFirstName")), " a un rendez-vous Télésanté à ", _interpolate(_named("appointmentStartDateTime"))])},
    "JoinButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre maintenant"])},
    "NotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel de rendez-vous"])}
  },
  "BookingHome": {
    "BookingInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ok, ", _interpolate(_named("patientFirstName")), "! Souhaitez-vous prendre un rendez-vous pour vous-même ou pour quelqu'un d'autre?"])},
    "Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moi"])},
    "AddADependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une personne à charge"])}
  },
  "BookingHeaderBar": {
    "DefaultHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre un rendez-vous"])}
  },
  "DependentSubheader": {
    "DefaultTextPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation pour"])},
    "UnknownDependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
  },
  "BookingClinic": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une clinique"])},
    "WhichClinicText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle clinique souhaitez-vous prendre rendez-vous?"])},
    "GenericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la récupération des données de la clinique où une réservation est possible"])},
    "ClinicSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de clinique en cours…"])}
  },
  "BookingProvider": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un prestataire"])},
    "WhichProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec qui souhaitez-vous prendre rendez-vous?"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des prestataires de soins de santé..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun prestataire actif trouvé"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème de connexion à la clinique"])},
    "NoProvidersFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " ne semble avoir aucun prestataire actif ou votre prestataire de soins de santé primaires n'a pas été activé."])},
    "SeeNextAvailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le prochain disponible"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "ProviderSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection de prestataire en cours…"])}
  },
  "BookingAppointmentType": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rendez-vous"])},
    "WhichAppointmentTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, super. Quel est le type de consultations dont vous avez besoin?"])},
    "ConnectWithATherapist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiquer avec un thérapeute"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des types de consultations..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun type de consultations trouvé"])},
    "LoadingFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette clinique, aucun type de consultations ne semble être activé."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "GenericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du chargement des données relatives aux consultations"])},
    "NoAppointmentTypesFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " ne semble avoir aucun type de consultations activé."])}
  },
  "BookingDisclaimer": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques"])},
    "UnderstandButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours…"])}
  },
  "WaitingRoomOverlay": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans la salle d'attente pour votre consultation virtuelle."])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation est terminée"])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "HomePageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])},
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous rencontrons des problèmes de connexion avec votre consultation virtuelle."])},
    "ConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que votre connexion Internet fonctionne et votre consultation reprendra automatiquement."])},
    "LoadingMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation va commencer dans un bref délai."])},
    "LoadingMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez attendre ici que votre prestataire se joigne à vous et assurez-vous que le volume de votre appareil est activé et que la caméra fonctionne correctement."])}
  },
  "PublicTelehealth": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin est parti"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin a quitté l'appel. Si vous ne pensez pas qu'il va revenir, vous pouvez raccrocher à tout moment"])}
  },
  "PublicWaitingRoomOverlay": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans la salle d'attente pour votre consultation virtuelle."])},
    "AppointmentEndedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation est terminée"])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation Télésanté est terminée. Passez une bonne journée!"])},
    "HomeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])}
  },
  "ClinicsHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes cliniques"])}
  },
  "ClinicAboutInfo": {
    "AboutUsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de nous"])}
  },
  "ClinicProviderInfo": {
    "ProvidersHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires"])}
  },
  "ClinicOperatingInfo": {
    "OperatingHoursHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d'ouverture générales"])},
    "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])}
  },
  "ClinicPageController": {
    "RejectConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment rejeter la demande de connexion de cette clinique?"])},
    "RemoveConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette connexion à la clinique?"])},
    "ConnectionRequestSentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de connexion envoyée!"])},
    "HealthCardIneligibleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de santé non éligible"])},
    "ConnectionProblemErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème de connexion à la clinique"])},
    "GenericConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion à la clinique"])},
    "GenericConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne sommes pas en mesure de vous connecter à cette clinique pour le moment"])},
    "DisconnectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecté de la clinique"])},
    "RemoveClinicAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la connexion?"])},
    "RemoveClinicAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous vraiment supprimer votre connexion à ", _interpolate(_named("clinicName")), "?"])},
    "BackButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
  },
  "ClinicsFindDependentController": {
    "CurrentClinicsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliniques disponibles actuellement connectées à Parent"])}
  },
  "ClinicSearchSpinner": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des cliniques..."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "FindAClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une clinique"])},
    "TryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réssayer"])},
    "NoClinicsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique disponible"])},
    "NoClinicsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique trouvée"])},
    "NoClinicsAvailableForDependent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il ne semble n'y avoir aucune clinique disponible à laquelle ", _interpolate(_named("dependentFirstName")), " puisse se connecter. Veuillez réessayer plus tard."])},
    "NoClinicsFoundForDependent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucune clinique associée à ", _interpolate(_named("dependentFirstName")), ". Cliquez ci-dessous pour le/la connecter à une clinique ou cliquez sur Retour pour sélectionner une autre personne."])},
    "NoClinicsAvailableForPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble qu'il n'y ait aucune clinique à laquelle vous puissiez vous connecter. Veuillez réessayer plus tard."])},
    "NoClinicsFoundForPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous n'ayez aucune clinique. Cliquez ci-dessous pour trouver une clinique."])}
  },
  "ClinicsFindHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une clinique"])}
  },
  "ClinicsFind": {
    "DependentHeaderTextPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de cliniques en tant que, "])},
    "SelectClinicText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la clinique avec laquelle vous souhaitez établir une connexion."])},
    "LocationUnavailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à la localisation non disponible."])},
    "ShowAllClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les cliniques disponibles"])},
    "NoClinicsFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique"])},
    "NoClinicsFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique n'a pu être trouvée."])},
    "SearchForClinicsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de cliniques..."])},
    "SearchByProvidersPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par prestataire..."])}
  },
  "ClinicViewHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur la clinique"])}
  },
  "ClinicView": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement de la clinique..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du chargement de la clinique"])},
    "LoadingFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu lors du chargement de cette clinique. Veuillez vérifier que la clinique est exacte, sinon réessayez."])},
    "Allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser"])},
    "Unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indisponible"])},
    "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez"])},
    "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "BookAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre un rendez-vous"])},
    "Deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "MessageUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous envoyer un message"])},
    "DisconnectFromClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de la clinique"])}
  },
  "ClinicsMobile": {
    "PendingClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliniques en attente"])},
    "YourClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos cliniques"])},
    "NoClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune connexion à la clinique"])},
    "NoClinicsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour entrer en contact avec votre prestataire de soins, veuillez cliquer sur le bouton « Trouver une clinique »."])}
  },
  "CannotConnectToClinicModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "CannotConnectToClinicHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de vous connecter à ", _interpolate(_named("clinicName")), "."])},
    "CannotConnectToClinicMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " n'autorise pas la création de profils à distance, or nous n'avons pas pu localiser votre profil. Il est fort probable que cela soit dû au fait que vous avez mis à jour votre numéro de carte d'assurance maladie ou que la clinique a mis votre dossier de patient à l'état inactif. Pour résoudre ce problème, veuillez contacter ", _interpolate(_named("clinicName")), " au ", _interpolate(_named("clinicPhoneNumber")), "."])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])}
  },
  "MailHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes messages"])}
  },
  "MailHome": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des messages..."])},
    "LoadingSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre boîte de réception est vide!"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])}
  },
  "MailboxController": {
    "LoadingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du chargement de la messagerie"])},
    "LoadingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite lors du chargement de la messagerie"])},
    "MessageSentSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre message a été envoyé à ", _interpolate(_named("clinicName")), "!"])},
    "MessageSentFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre message n'a pas pu être envoyé à ", _interpolate(_named("clinicName"))])},
    "ArchiveMessageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a été archivé."])},
    "UnarchiveMessageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message a été désarchivé."])},
    "SuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])}
  },
  "MessageFeed": {
    "NoMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun message"])},
    "InboxMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte de réception"])},
    "SentMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
    "ArchiveMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])}
  },
  "MessageCard": {
    "SentMessageHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["À: ", _interpolate(_named("recipientNames"))])},
    "ReceivedMessageHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De: ", _interpolate(_named("senderName"))])}
  },
  "MessageRead": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le message"])},
    "ClinicSectionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinique"])},
    "SubjectSectionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "UnarchiveButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désarchiver"])},
    "ArchiveButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiver"])},
    "ReplyButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre"])},
    "CloseButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "Message": {
    "FromMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De:"])},
    "MessageReceivedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["le ", _interpolate(_named("messageDate"))])}
  },
  "MessageCompose": {
    "NoClinicsAvailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clinique disponible pour la messagerie"])},
    "SubjectPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir l'objet ici"])},
    "MessagePlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir votre message ici"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours..."])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "ClinicPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une clinique"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "MessageHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "ReplyHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre au message"])},
    "NewMessageHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message"])},
    "AddAttachmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une pièce jointe"])},
    "SendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "MessageLayoutWrapper": {
    "ClinicHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinique"])},
    "SubjectHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "BodyHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "AttachmentsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])}
  },
  "AttachmentSelector": {
    "AddAttachmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une pièce jointe"])},
    "GenericAttachmentErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de pièce jointe"])},
    "MaxNumberOfFilesErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dépasse la limite de ", _interpolate(_named("maxNumberOfFiles")), " fichiers joints par message"])},
    "GenericAttachmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'attachement du fichier au message"])},
    "RemoveAttachmentAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la pièce jointe"])},
    "RemoveAttachmentAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette pièce jointe?"])},
    "DisagreeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "AgreeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "RemoveAttachmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la suppression du fichier du message"])}
  },
  "FileChooser": {
    "GenericAttachmentErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de pièce jointe"])},
    "MaxFileSizeErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fileName")), " dépasse la taille maximale de ", _interpolate(_named("maxFileSize")), " Mo."])}
  },
  "AppointmentsHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes rendez-vous"])}
  },
  "AppointmentsHome": {
    "BookAnAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre un rendez-vous"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de vos rendez-vous..."])},
    "NoAppointmentsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que vous n'ayez aucun rendez-vous. Cliquez ci-dessous pour prendre rendez-vous."])},
    "NoDependentAppointmentsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dependentFirstName")), " semble n'avoir aucun rendez-vous. Cliquez ci-dessous pour prendre rendez-vous pour cette personne"])}
  },
  "AppointmentsFeed": {
    "PreviousAppointmentsTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rendez-vous antérieurs (", _interpolate(_named("numberOfPreviousAppointments")), ")"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de récupération de la liste des rendez-vous"])}
  },
  "AppointmentInfo": {
    "MobileHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous avec"])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "AppointmentPage": {
    "CancelAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le rendez-vous"])},
    "StartAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer votre consultation Télésanté"])},
    "AboutAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de votre rendez-vous"])},
    "UnableToCancelAppointmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce rendez-vous ne peut plus être annulé en ligne. Veuillez contacter votre clinique"])},
    "FailedToCancelAppointmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'annulation du rendez-vous. Veuillez contacter votre clinique si le problème persiste."])},
    "CancelAppointmentConfirmationDialogHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le rendez-vous"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vraiment annuler ce rendez-vous?"])},
    "CancelAppointmentDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "CancelAppointmentAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "RescheduleAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier votre rendez-vous"])}
  },
  "HeaderBarProfile": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])}
  },
  "AccountHome": {
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "SaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])}
  },
  "ProfileControlButtons": {
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "SaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de"])}
  },
  "ProfileInputFields": {
    "FirstNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "MiddleNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deuxième prénom"])},
    "LastNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "EmailField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "HealthCareProvinceCodeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province des soins de santé"])},
    "HealthNumberField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte Santé"])},
    "VersionCodeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "BirthDateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "SexField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe à la naissance"])},
    "CellPhoneField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone portable"])},
    "AddressField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "CityField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "CountryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "TimezoneField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuseau horaire"])},
    "PasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "PasswordFieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir votre mot de passe ici"])},
    "ConfirmPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "ConfirmPasswordFieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retaper votre mot de passe ici"])},
    "HealthInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos renseignements médicaux"])},
    "NewbornDependentIndicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer si cette personne à charge n'a pas de numéro d'assurance maladie propre parce qu'elle est un nouveau-né."])},
    "NewbornToggleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La personne à charge est-elle un nouveau-né?"])},
    "AgeAndSexInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur votre âge et votre sexe"])},
    "ContactInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos coordonnées"])},
    "PrimaryPhoneNumberDescriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un numéro de téléphone que le médecin pourra utiliser pour vous contacter, si nécessaire, au sujet de votre rendez-vous."])},
    "PasswordRequirementsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères."])},
    "GenericFieldPlaceHolderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir vos informations ici"])},
    "FieldPlaceHolderText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saisir votre ", _interpolate(_named("fieldName")), " ici"])}
  },
  "ProfileController": {
    "StateRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
    "ProvinceRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "USPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code ZIP"])},
    "CanadaPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "SexMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
    "SexFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
    "GenericNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "TurnOffEmailNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désactivez le courrier électronique, votre capacité à recevoir des mises à jour et des rappels de rendez-vous sera affectée"])},
    "ConfirmationButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "SomethingWentWrongMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, un problème est survenu. Veuillez réessayer ou contacter le service d'assistance"])},
    "PasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "EmailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])}
  },
  "PatientProfileModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant la réservation de votre première consultation, le médecin a besoin que vous remplissiez le formulaire ci-dessous."])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "ConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer que ces informations sont correctes."])},
    "NotRegisteredMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actuellement, votre profil de patient n'est pas enregistré auprès de ", _interpolate(_named("clinicName")), "."])}
  },
  "ProfileModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le patient"])},
    "CloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "SubmitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])}
  },
  "RequiredProfileModal": {
    "UpdateInfoButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les informations"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "UnsupportedProvinceErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province non prise en charge"])},
    "UnsupportedProvinceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuellement, votre province de soins de santé n'est pas prise en charge."])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "IneligibleHealthCardErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de santé non éligible"])},
    "ConnectedToClinicMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté à la clinique"])},
    "NoRecordHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier introuvable"])},
    "NoRecordMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " n'accepte pas de nouveaux patients pour le moment."])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "EditProfileButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
    "BookingUnavailableHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservation non disponible"])},
    "BookingUnavailableMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actuellement, il n'est pas possible de faire une réservation auprès de ", _interpolate(_named("clinicName")), ". Contactez votre clinique si cela persiste"])},
    "CreatePatientRecordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour vous connecter, vous devez créer un dossier Patient auprès de ", _interpolate(_named("clinicName"))])},
    "CreateDependentPatientRecordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour ", _interpolate(_named("dependentFirstName")), ", vous devez créer un dossier patient auprès de ", _interpolate(_named("clinicName"))])},
    "ConnectToClinicHeaderText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connectez-vous à ", _interpolate(_named("clinicName"))])},
    "ViewProfileButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le profil"])},
    "ConnectButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez"])},
    "IncompleteProfileHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil incomplet"])},
    "IncompleteProfileMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez compléter votre profil pour être ajouté à ", _interpolate(_named("clinicName"))])},
    "DependentIncompleteProfileMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez compléter le profil de ", _interpolate(_named("dependentFirstName")), " pour l'ajouter à $", _interpolate(_named("clinicName"))])},
    "ConnectionMessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite me connecter à votre clinique"])},
    "DependentConnectionMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je souhaite connecter ma personne à charge ", _interpolate(_named("dependentFirstName")), " $", _interpolate(_named("dependentLastName")), " à votre clinique"])},
    "PatientHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le patient"])},
    "DependentHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur la personne à charge"])}
  },
  "DependentsHome": {
    "DependentsInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous pour l'affichage et la mise à jour des informations sur une personne à charge existante, ou pour l'ajout d'une nouvelle personne à charge."])},
    "AddADependentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une personne à charge"])}
  },
  "DependentsHeaderBar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes personnes à charge"])}
  },
  "DependentsEventStateSearch": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des personnes à charge"])},
    "NoResultsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune personne à charge trouvée"])}
  },
  "DependentsProfile": {
    "ConnectedClinicTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliniques connectées"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veuillez patienter jusqu'à la fin de cette opération"])},
    "SaveAndContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et continuer"])},
    "AddAConnectionButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle connexion"])},
    "DeleteDependentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la personne à charge"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "LoadingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du chargement du profil de la personne à charge"])},
    "NewbornDependentAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette personne à charge ayant moins de ", _interpolate(_named("numberOfMonths")), " mois, elle ne peut pas être enregistrée avec un numéro de carte Santé personnel. Si tel est le cas, c'est le numéro d'assurance maladie du tuteur qui doit être utilisé pour rattacher la personne à charge. Veuillez confirmer si cette situation est la vôtre.\n Cette personne à charge dispose-t-elle d'un numéro de carte Santé personnel?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "SavingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'enregistrement des informations du profil de la personne à charge"])},
    "DeleteDependentAlertHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la personne à charge"])},
    "DeleteDependentAlertMessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette personne à charge? Tous les rendez-vous ultérieurs seront annulés!"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "DeleteDependentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de l'enregistrement de la personne à charge impossible"])},
    "UnCancellableAppointmentsAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous ultérieurs non annulables!"])},
    "UnCancellableAppointmentsAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous poursuivre la suppression de la personne à charge? Vous pouvez devoir contacter la ou les cliniques pour procéder à une annulation manuelle"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "DeleteClinicConnectionAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la connexion à la clinique"])},
    "DeleteClinicConnectionAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer cette connexion à la clinique?"])},
    "Disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "DeleteClinicErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression de la connexion à la clinique impossible"])},
    "EditDependentHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une personne à charge"])},
    "AddDependentHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une personne à charge"])}
  },
  "HeaderBarSecurity": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])}
  },
  "SecurityHome": {
    "UpdateEmailOrPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour modifier votre adresse courriel ou votre mot de passe, vous devez déverrouiller les champs avec votre adresse courriel et votre mot de passe actuels."])},
    "HowToProceedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer, cliquez sur l'un des boutons ci-dessous."])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "PasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "ChangePasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon mot de passe"])},
    "ChangeEmailButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon adresse courriel"])},
    "DeactivateAccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver mon compte"])},
    "DeactivateAccountConfirmationAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'action"])},
    "DeactivateAccountConfirmationAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment désactiver votre compte?"])},
    "Deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "SecurityChangePassword": {
    "ChangePasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité, avant de pouvoir mettre à jour votre compte, nous avons besoin de votre mot de passe actuel."])},
    "CurrentPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
    "NewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "ConfirmNewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le nouveau mot de passe"])},
    "ChangePasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon mot de passe"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "SecurityChangeEmail": {
    "ChangeEmailInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez changer l'adresse courriel que vous utilisez pour vous connecter, nous allons avoir besoin de votre mot de passe"])},
    "CurrentPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "ChangeEmailButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon adresse courriel"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "HeaderBarPrivacy": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])}
  },
  "PrivacyHome": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
    "NotificationSettingsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez à jour vos paramètres de notification"])},
    "NotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "AllowTextMessagesFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les SMS"])},
    "AllowEmailsFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser les courriels"])},
    "ViewPrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter la Politique de confidentialité"])},
    "ViewTermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter les Conditions d'utilisation"])}
  },
  "UserProfileController": {
    "GenericNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "TurnOffEmailNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désactivez le courrier électronique, votre capacité à recevoir des mises à jour, telles que des rappels de rendez-vous, de la part de MyHealthAccess sera affectée"])},
    "ConfirmationButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je comprends"])},
    "TurnOffTextNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous désactivez les messages SMS, votre capacité à recevoir des mises à jour, telles que des rappels de rendez-vous, de la part de MyHealthAccess sera affectée"])},
    "MissingRequiredFieldsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs obligatoires de votre profil sont incorrects ou manquants. Veuillez les mettre à jour avant de les enregistrer"])},
    "OkButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’accord"])},
    "CheckEmailAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre nouvelle adresse courriel"])},
    "CheckEmailAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour terminer le changement d'adresse courriel, veuillez aller à votre nouvelle adresse courriel. Des instructions de confirmation y ont été envoyées."])}
  },
  "AccountIdCodePage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de vérification"])},
    "VerificationCodeInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour la vérification de votre identité, veuillez fournir ce code à votre médecin."])}
  },
  "Kiosk": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des données du Kiosque"])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du chargement des données du Kiosque"])},
    "TimeoutAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes toujours là?"])},
    "TimeoutAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dans un souci de protection de votre vie privée, si le Kiosque reste inactif, votre session sera interrompue dans ", _interpolate(_named("timeoutSeconds"))])}
  },
  "KioskHome": {
    "SeeADoctorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter un médecin maintenant"])},
    "SeeADoctorDescriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous souhaitez consulter un médecin, préparez votre carte Santé et appuyez sur le bouton ci-dessous."])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réssayer"])},
    "HealthCardReaderScanningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numérisation de la carte Santé..."])}
  },
  "KioskReason": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif du rendez-vous"])},
    "AppointmentReasonInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touchez la zone de texte vierge ci-dessous, puis saisissez la raison pour laquelle vous souhaitez consulter le médecin aujourd'hui."])},
    "AppointmentReasonPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touchez ici pour ouvrir le clavier."])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "NextButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])}
  },
  "KioskProfileForm": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balayez votre carte Santé maintenant"])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez balayer votre carte Santé maintenant ou saisir manuellement les informations relatives à celle-ci dans les champs de texte ci-dessous."])},
    "RequiredFieldsInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseigner les champs obligatoires suivants:"])},
    "UseOfSiteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisation du service et du site Web est soumise à nos"])},
    "And": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
    "TermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
    "PrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "BookAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un rendez-vous"])},
    "FieldErrorsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs de champs"])},
    "FieldErrorsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains champs sont incorrects. Veuillez vérifier les champs en surbrillance"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne pouvons pas répondre à votre demande pour le moment. Veuillez réessayer plus tard."])}
  },
  "KioskFinished": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre rendez-vous est terminé"])},
    "InformationDeletedConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans un souci de protection de votre vie privée, vos informations ont été supprimées du Kiosque."])},
    "PickUpPrescriptionInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour récupérer votre ordonnance, veuillez vous adresser au pharmacien."])},
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au revoir"])}
  },
  "KioskWaitingRoomOverlay": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre consultation a été réservée"])},
    "WaitingRoomText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le docteur sera avec vous dans un moment."])},
    "CancelAppointmentInformationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous annulez votre rendez-vous, dans un souci de protection de votre vie privée, vos informations seront immédiatement effacées du Kiosque, et vous perdrez votre place dans la file d'attente."])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la page d'accueil"])},
    "CancelAppointmentNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation d'un rendez-vous"])},
    "CancelAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment annuler votre rendez-vous?"])},
    "YesButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "NoButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
  },
  "KioskTelehealth": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin est parti"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le médecin a quitté l'appel. Si vous ne pensez pas qu'il va revenir, vous pouvez raccrocher à tout moment"])},
    "TimeoutErrorHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer à patienter?"])},
    "TimeoutErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dans un souci de protection de votre vie privée, votre session sera interrompue dans ", _interpolate(_named("timeoutSeconds")), " secondes."])},
    "RejoinCallMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si c'était une erreur, vous pouvez vous réinscrire."])},
    "RejoinCallAfterNetworkErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela peut être dû à une erreur de réseau. Souhaitez-vous vous réinscrire?"])},
    "SessionEndedNotificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La session est terminée"])},
    "StayButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rester"])},
    "LeaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])}
  },
  "TermsAndConditionsPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])}
  },
  "PrivacyPolicyPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
  },
  "CloseButton": {
    "ButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "ErrorPage": {
    "404Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous nous excusons"])},
    "404Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne trouvons pas ce que vous cherchez"])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retournez à l’accueil"])},
    "403Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non autorisé"])},
    "403Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas l’autorisation pour accéder à cette ressource."])},
    "ErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite."])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Code d’état HTTP: ", _interpolate(_named("code"))])}
  },
  "Common": {
    "Error": {
      "Generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande n’a pu être traitée. Veuillez contacter le service d’assistance si le problème persiste."])}
    }
  },
  "OrgAqsRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection en cours…"])},
    "InvalidHealthNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro d’assurance maladie utilisé est non valide. Veuillez le corriger avant de continuer."])},
    "GoBackText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  },
  "OrgProviderGroupBookingRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection en cours…"])},
    "InvalidHealthNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro d’assurance maladie utilisé est non valide. Veuillez le corriger avant de continuer."])},
    "GoBackText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])}
  },
  "DocumentPreview": {
    "NotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun aperçu disponible"])}
  },
  "BookingProviderGroup": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le groupe de soins"])},
    "WhichCareGroupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De quel groupe de soins avez-vous besoin?"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de groupes de soins en cours…"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la fenêtre"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème de connexion à la clinique"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "SearchByProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par prestataire"])},
    "ProviderGroupSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection du groupe de soins en cours…"])}
  },
  "ChartUploadDocumentReviewModal": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier les documents à télécharger dans le dossier"])},
    "UploadErrorTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de télécharger le document dans le dossier ", _interpolate(_named("documentName"))])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez les documents sélectionnés dans le dossier électronique du patient."])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours…"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des documents en cours…"])}
  }
}