import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28abc5f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-col align-items-center justify-content-center m-t-96"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-col row-gap-24"
}
const _hoisted_3 = { class: "d-flex flex-col row-gap-48" }
const _hoisted_4 = { class: "title text-center" }
const _hoisted_5 = { class: "subtitle text-center" }
const _hoisted_6 = {
  key: 0,
  class: "column center align-center d-flex flex-col row-gap-12"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "d-flex flex-col align-items-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { style: {"background-color":"white"} }, {
    "content-center": _withCtx(() => [
      (_ctx.bookingClosed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$mhat("LiveChatBooking.LiveChatClosedMessage")), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$mhat("LiveChatBooking.Title")), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$mhat("LiveChatBooking.Subtitle")), 1)
            ]),
            (!_ctx.reason)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_BaseButton, {
                    pattern: _ctx.ButtonColorPattern.COLORED_TEXT_BORDER_FUSION,
                    color: _ctx.ButtonColor.FUSION_SELECTOR,
                    id: "help-new-service",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.reason='New Service Inquiry')),
                    ariaLabel: _ctx.$mhat('LiveChatBooking.NewService')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat("LiveChatBooking.NewService")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "ariaLabel"]),
                  _createVNode(_component_BaseButton, {
                    pattern: _ctx.ButtonColorPattern.COLORED_TEXT_BORDER_FUSION,
                    color: _ctx.ButtonColor.FUSION_SELECTOR,
                    id: "help-existing-service",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (this.reason='Existing Service Inquiry')),
                    ariaLabel: _ctx.$mhat('LiveChatBooking.ExistingService')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat("LiveChatBooking.ExistingService")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "ariaLabel"]),
                  _createVNode(_component_BaseButton, {
                    pattern: _ctx.ButtonColorPattern.COLORED_TEXT_BORDER_FUSION,
                    color: _ctx.ButtonColor.FUSION_SELECTOR,
                    id: "help-available-services",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (this.reason='Available Services Inquiry')),
                    ariaLabel: _ctx.$mhat('LiveChatBooking.AvailableService')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat("LiveChatBooking.AvailableService")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "ariaLabel"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_TextArea, {
                    placeholder: _ctx.$mhat('LiveChatBooking.FirstMessagePlaceholder'),
                    value: this.firstMessage,
                    rows: 7,
                    maxLength: 250,
                    onInput: _cache[3] || (_cache[3] = (input) => this.firstMessage = input.value),
                    "aria-required": true,
                    label: _ctx.$mhat('LiveChatBooking.ARIA.FirstMessageTextAreaLabel'),
                    hideLabel: true
                  }, null, 8, ["placeholder", "value", "label"]),
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_BaseButton, {
                      id: "start-chat",
                      onClick: _ctx.bookLiveChat,
                      disabled: !_ctx.firstMessage,
                      color: _ctx.ButtonColor.PRIMARY,
                      pattern: _ctx.ButtonColorPattern.FILL,
                      size: _ctx.ButtonSize.SMALL,
                      ariaLabel: _ctx.$mhat('LiveChatBooking.StartConversation'),
                      "no-caps": ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$mhat("LiveChatBooking.StartConversation")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled", "color", "pattern", "size", "ariaLabel"])
                  ])
                ]))
          ]))
    ]),
    "content-bottom": _withCtx(() => [
      (_ctx.bookingClosed)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            onClick: _ctx.bookLiveChat,
            color: _ctx.ButtonColor.PRIMARY,
            pattern: _ctx.ButtonColorPattern.FILL
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$mhat("LiveChatBooking.RetryButton")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "color", "pattern"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}