import {AqsAppointmentDto, ClinicAppointmentTransfer} from "@/open_api/generated";
import AppointmentQueueService from "@/lib/appointment/aqs/service/patient/AppointmentQueueService";
import moment from "moment";
import {QueuedAppointmentType} from "@/lib/appointment/aqs/model/QueuedAppointmentType";
import QueuedAppointmentService from "@/lib/appointment/aqs/service/patient/QueuedAppointmentService";
import ConfigStore from "@/lib/vuex/modules/Config.store";
import PatientProfileService from "@/lib/patient/PatientProfileService";
import AuthStore from "@/lib/vuex/auth.store";

export default class LiveChatService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public async getExistingChat(): Promise<AqsAppointmentDto>
	{
		const queuedAppointmentService = new QueuedAppointmentService();
		const clinicId = ConfigStore.configConstants.fusion_clinic_id;
		const queue = await (new AppointmentQueueService()).getLiveChatQueueForClinic(clinicId);

		let existingQueuedChats = await queuedAppointmentService.getScheduledQueuedAppointments(
			moment().utc().subtract(30, "minutes"),
			moment().utc().add(30, "minutes"));

		existingQueuedChats = existingQueuedChats.filter(function (clinicAppointmentTransfer: ClinicAppointmentTransfer)
		{
			return clinicAppointmentTransfer.appointment_type === QueuedAppointmentType.LiveChat.toString();
		});

		if (existingQueuedChats.length > 0)
		{
			return await queuedAppointmentService.getQueuedAppointment(
				clinicId,
				queue.id,
				existingQueuedChats[0].queued_appointment_id);
		}
		return null;
	}

	/**
	 * create or join existing live chat session for the current user.
	 * @return aqsAppointment - the live chat appointment
	 */
	public async createLiveChatSession(reason: string): Promise<AqsAppointmentDto>
	{
		const queuedAppointmentService = new QueuedAppointmentService();
		const clinicId = ConfigStore.configConstants.fusion_clinic_id;
		const queue = await (new AppointmentQueueService()).getLiveChatQueueForClinic(clinicId);

		const patientProfileService = new PatientProfileService();
		const patientProfile = patientProfileService.getPatientProfile(AuthStore.loggedInPatient.id);

		const reasonField = [patientProfile.locale.toUpperCase(), patientProfile.fusionEAC, patientProfile.fusionOrganization, reason].join(" | ");

		return await queuedAppointmentService.bookQueuedAppointment(
			clinicId,
			queue.id,
			QueuedAppointmentType.LiveChat,
			reasonField,
			"Live Chat",
			true);
	}
}
