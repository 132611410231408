import {AppointmentsRoute} from "@/router/patient_user/appointment.routes";
import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import {QueuedAppointmentType} from "@/lib/appointment/aqs/model/QueuedAppointmentType";
import QueuedAppointmentService from "@/lib/appointment/aqs/service/patient/QueuedAppointmentService";
import ClinicAppointment from "@/lib/appointment/model/ClinicAppointment";

export class AppointmentRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: AppointmentRouteCollection;

	public static getInstance(): AppointmentRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new AppointmentRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get Home(): string
	{
		return getRouteOrWhitelabelRoute(AppointmentsRoute.Home);
	}

	get View(): string
	{
		return getRouteOrWhitelabelRoute(AppointmentsRoute.View);
	}

	/**
	 * get the telehealth route
	 * @param appointmentId - the id of the appointment that the telehealth session is for.
	 * @constructor
	 */
	public async Telehealth(appointmentId: string): Promise<string>
	{
		const clinicAppointment = await (new PatientAppointmentService()).getClinicAppointment(appointmentId);

		return this.TelehealthForClinicAppointment(clinicAppointment);
	}

	/**
	 * the same as the Telehealth() method but does not require async processing
	 * as the clinic appointment is provided as an argument
	 * @param appointment - clinic appointment for which you want the telehealth route
	 */
	public TelehealthForClinicAppointment(appointment: ClinicAppointment)
	{
		if (appointment.isChatType)
		{
			return this.TelehealthTextChat;
		}
		return getRouteOrWhitelabelRoute(AppointmentsRoute.Telehealth);
	}

	/**
	 * get the route for AQS telehealth.
	 * @param clinicId - clinic in which the AQS appointment is booked.
	 * @param queueId - queue in which the AQS appointment is booked.
	 * @param appointmentId - the id of the queued appointment.
	 * @constructor
	 */
	public async TelehealthAqs(clinicId: string, queueId: string, appointmentId: string): Promise<string>
	{
		const appointment = await (new QueuedAppointmentService()).getQueuedAppointment(clinicId, queueId, appointmentId);

		if (appointment.communication_type === QueuedAppointmentType.Chat)
		{
			if (appointment.extra_info?.extended_communication_type as QueuedAppointmentType === QueuedAppointmentType.LiveChat)
			{
				return this.TelehealthLiveChat;
			}
			return this.TelehealthTextChatAqs;
		}
		return getRouteOrWhitelabelRoute(AppointmentsRoute.TelehealthAqs);
	}

	get TelehealthTextChat(): string
	{
		return getRouteOrWhitelabelRoute(AppointmentsRoute.TelehealthTextChat);
	}

	get TelehealthTextChatAqs(): string
	{
		return getRouteOrWhitelabelRoute(AppointmentsRoute.TelehealthTextChatAqs);
	}

	get TelehealthLiveChat(): string
	{
		return getRouteOrWhitelabelRoute(AppointmentsRoute.TelehealthLiveChat);
	}
}

export const appointmentRouteCollection = AppointmentRouteCollection.getInstance();
