import {AnalyticEvent} from "@/lib/anayltics/AnalyticEvent";
import {LoggingAPI, PublicAPI} from "@/lib/services/Api";

export default class AnalyticsService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * log that the current page has been viewed.
	 * @param url - the url to record the page view for. defaults to current page.
	 */
	public logPageView(url: string = window.location.href): void
	{
		this.logAnalyticEvent(AnalyticEvent.PAGE_VIEW, {page_url: url});
	}

	/**
	 * log that the user has ended a live chat session
	 * @param queuedAppointmentId
	 */
	public logEndLiveChat(queuedAppointmentId: string): void
	{
		this.logAnalyticEvent(AnalyticEvent.END_LIVE_CHAT, {queuedAppointmentId: queuedAppointmentId});
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * log an analytics event to the backend
	 * @param event - the event to log
	 * @param eventData - optional event data
	 */
	protected logAnalyticEvent(event: AnalyticEvent, eventData: any = null): void
	{
		LoggingAPI().logAnalyticEvent({
			event,
			data: eventData,
		});
	}
}
