import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7501ce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      fill: "clear",
      class: "star-button ion-no-padding"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, {
          icon: _ctx.filled === true ? _ctx.STAR_FILLED : _ctx.STAR_OUTLINE,
          class: "star-icon"
        }, null, 8, ["icon"])
      ]),
      _: 1
    })
  ]))
}