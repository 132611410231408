
	import {Options, Prop, Vue} from "vue-property-decorator";
	import Star from "./star.vue";

	@Options({components: {Star}, emits: ["ratingUpdate"]})
	export default class RatingStar extends Vue
	{
		@Prop({type: Number, required: true}) numberOfStars: number;
		rating = 0;

		get fill(): boolean[]
		{
			return new Array(this.numberOfStars).fill(false);
		}

		private updateRating(rating: number)
		{
			this.rating = rating;
			this.$emit("ratingUpdate", rating);
		}
	}
