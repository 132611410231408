
	import {Options, Prop, Vue, Watch} from "vue-property-decorator";
	import TelehealthHeader from "@/views/patient_user/telehealth/components/TelehealthHeader.vue";
	import {ChatItem, ChatItemType, MessageOrigin} from "@/lib/telehealth/models/telehealth.types";
	import ChatBubble from "@/components/telehealth/ChatBubble.vue";
	import {TextareaChangeEventDetail} from "@ionic/core";
	import {AddedListener, addKeyboardListener} from "@/lib/plugins/Keyboard";
	import {IonFabButton, IonTextarea, modalController} from "@ionic/vue";
	import FileChooser from "@/components/Inputs/FileChooser.vue";
	import {AllowedFileTypes} from "@/lib/document/model/AllowedFileTypes";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import DocumentPreviewModal from "@/components/Documents/DocumentPreviewModal.vue";
	import DocumentService from "@/lib/document/service/DocumentService";

	interface ChatViewModalMethods
	{
		send?(message: string): void;

		input?(): void;
	}

	@Options({
		components:
			{
				FileChooser,
				ChatBubble,
				TelehealthHeader,
				IonTextarea,
				IonFabButton,
			},
		emits: ["focusin", "focusout", "input", "send", "sendFile"],
	})
	export default class ChatView extends Vue
	{
		@Prop(Array) public readonly chatItems: ChatItem[];
		@Prop(Boolean) public readonly typing: boolean;
		@Prop(Boolean) public readonly disabled: boolean;
		@Prop({type: Boolean, default: false}) documentChatDisabled: boolean;
		@Prop({type: Boolean, default: true}) showInputs: boolean;

		public ButtonColorPattern = ButtonColorPattern;
		public ButtonColor = ButtonColor;

		typingMessage: ChatItem = {
			type: ChatItemType.TYPING,
			messageOrigin: MessageOrigin.Received,
			message: "",
		};

		public value = "";
		public selectedId: string = null;

		keyboardListener: AddedListener = null;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		mounted()
		{
			const chatFormEl = this.$refs.chatForm as HTMLElement;
			this.keyboardListener = addKeyboardListener(
				() => chatFormEl.style.setProperty("--padding-bottom", "0px"),
				() => chatFormEl.style.setProperty("--padding-bottom", "env(safe-area-inset-bottom)"),
			);
		}

		beforeUnmount()
		{
			this.keyboardListener?.remove();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public send()
		{
			if (this.canSendTextMessage)
			{
				this.$emit("send", this.value);

				this.value = "";
				((this.$refs.chatInput as typeof IonTextarea).$el as HTMLIonTextareaElement).setFocus();
			}
		}

		/**
		 * send one or more files as chat messages
		 * @param files - list of files to send
		 */
		public sendFiles(_, files: File[])
		{
			if (this.canSendFileMessage)
			{
				files.forEach((file) =>
				{
					this.$emit("sendFile", file);
				});
			}
		}

		/**
		 * watch for new chat messages.
		 */
		@Watch("chatCount")
		@Watch("typing")
		onChatUpdate()
		{
			// on new message scroll them into view.
			window.setTimeout(() => this.scrollToBottom(), 250);
		}

		public scrollToBottom()
		{
			const scrollEl = this.$refs.chatItemEl as HTMLElement;
			scrollEl.scrollTo({top: scrollEl.scrollHeight});
		}

		public onChange({detail}: CustomEvent<TextareaChangeEventDetail>)
		{
			this.$emit("input");
			this.value = detail.value;
		}

		public async onClick(item: ChatItem): Promise<void>
		{
			if (item.type === ChatItemType.MESSAGE)
			{
				this.selectedId = item.id === this.selectedId ? null : item.id;
			}
			else if (item.type === ChatItemType.DOCUMENT)
			{
				const documentService = new DocumentService();
				const document = await documentService.getDocument(item.message);

				if (document.canPreview)
				{
					const modal = await modalController.create({
						component: DocumentPreviewModal,
						componentProps: {
							document,
						},
					});

					await modal.present();
				}
				else
				{
					await document.saveToLocalComputer();
				}
			}
		}

		public isActive(item: ChatItem): boolean
		{
			return item.id === this.selectedId;
		}

		public focusOutDelay(): void
		{
			// We must delay the focusout event because the user may have hit the "send message button"
			// in which case we are about to re-focus our selfs. This prevents the on screen keyboard from going away.
			window.setTimeout(() =>
			{
				if ((this.$refs.chatInput as typeof IonTextarea).$el.getElementsByTagName("textarea")[0] !== document.activeElement)
				{
					this.$emit("focusout");
				}
			}, 100);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get chatCount(): number
		{
			return this.chatItems.length;
		}

		get canSendTextMessage()
		{
			return this.value.length > 0 && !this.disabled;
		}

		get canSendFileMessage()
		{
			return !this.disabled && !this.documentChatDisabled;
		}

		get allowedAttachmentFileExtensions()
		{
			return AllowedFileTypes.join(",");
		}

		get sendFileTooltip(): string
		{
			return this.$mhat("ChatView.SendFileTooltip");
		}
	}
