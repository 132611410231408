
	import {Options, Vue} from "vue-property-decorator";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import {modalController} from "@ionic/vue";
	import OnDemandBookingHoursModal from "@/views/patient_user/odb/booking/OnDemandBookingHoursModal.vue";
	import ConfigStore from "@/lib/vuex/modules/Config.store";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import LiveChatService from "@/lib/appointment/aqs/service/patient/LiveChatService";
	import AppointmentQueueService from "@/lib/appointment/aqs/service/patient/AppointmentQueueService";
	import TextArea from "@/components/Inputs/TextArea.vue";
	import {$mhat} from "@/i18n";
	import {AqsPatientAPI} from "@/lib/services/Api";

	@Options({
		methods: {$mhat},
		components: {TextArea, PageWrapper, BaseButton},
	})
	export default class LiveChatBooking extends Vue
	{
		public bookingClosed = false;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		private reason = null;
		private firstMessage = "";

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async created(): Promise<void>
		{
			await this.attemptReconnectToChat();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async attemptReconnectToChat(): Promise<void>
		{
			const clinicId = ConfigStore.configConstants.fusion_clinic_id;
			const existingChat = await (new LiveChatService()).getExistingChat();
			if (existingChat != null)
			{
				// check if chat was ended by user and don't reconnect if it was
				const aqsPatientAPI = AqsPatientAPI();
				const appointment = (await aqsPatientAPI.getAssociatedClinicAppointment(clinicId, existingChat.queue_id, existingChat.id)).data;
				if (appointment.appointment_ended)
				{
					return;
				}
				// Otherwise rejoin
				this.$mhaRouterReplace(
					await this.Route.Appointments.TelehealthAqs(clinicId, existingChat.queue_id, existingChat.id),
					{
						params: {
							clinicId: clinicId,
							queueId: existingChat.queue_id,
							queuedAppointmentId: existingChat.id,
							firstMessage: this.firstMessage,
						},
					},
				);
			}
		}

		/**
		 * book a live chat and redirect
		 */
		public async bookLiveChat(): Promise<void>
		{
			try
			{
				if (this.reason == null || this.firstMessage === "")
				{
					return;
				}
				const clinicId = ConfigStore.configConstants.fusion_clinic_id;

				const appointment = await (new LiveChatService()).createLiveChatSession(this.reason);

				this.$mhaRouterReplace(
					await this.Route.Appointments.TelehealthAqs(clinicId, appointment.queue_id, appointment.id),
					{
						params: {
							clinicId: clinicId,
							queueId: appointment.queue_id,
							queuedAppointmentId: appointment.id,
							firstMessage: this.firstMessage,
						},
					},
				);
			}
			catch (error)
			{
				if (error instanceof ErrorResponse && error.is(ErrorCode.AqsQueueAvailability))
				{
					this.bookingClosed = true;
					await this.presentLiveChatHoursModal();
				}
				else
				{
					// Generic error
					console.error(error);
					NotificationService.genericErrorNotification();
				}
			}
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		/**
		 * Present a modal to the user detailing livechat hours
		 * @private
		 */
		private async presentLiveChatHoursModal(): Promise<void>
		{
			const clinicId = ConfigStore.configConstants.fusion_clinic_id;
			const queue = await (new AppointmentQueueService()).getLiveChatQueueForClinic(clinicId);

			// LiveChat closed. Allow user to see hours.... possibly simplify this to say, "Live chat reopens at XX:XX"
			NotificationService.notify({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Warning,
				title: this.$mhat("LiveChatBooking.LiveChatClosedTitle"),
				message: this.$mhat("LiveChatBooking.LiveChatClosedMessage"),
				confirm: {
					message: this.$mhat("LiveChatBooking.LiveChatClosedConfirmMessage"),
					callback: async () =>
					{
						const modal = await modalController.create(
							{
								component: OnDemandBookingHoursModal,
								cssClass: "medium-modal",
								componentProps: {queue},
							});
						await modal.present();
					},
				},
			});
		}
	}
