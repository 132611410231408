import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import AqsAppointmentType from "@/views/patient_user/appointment/aqs/AqsAppointmentType.vue";
import OrgAqsRedirect from "@/views/patient_user/appointment/aqs/OrgAqsRedirect.vue";
import LiveChatBooking from "@/views/patient_user/appointment/aqs/LiveChatBooking.vue";

export enum AqsBookingRoutes
{
	AppointmentType = "AqsAppointmentType",
	OrganizationRedirect = "OrganizationRedirect",
	LiveChat = "AqsBookLiveChat",
}

export const aqsBookingRoutes: RouteRecordRaw[] = [
	{
		path: "/aqs/book/clinic/:clinicId/appointmentType",
		name: AqsBookingRoutes.AppointmentType,
		props: (route) =>
		{
			return Object.assign(
				{
					backRoute: route.query.backRoute,
					forwardRoute: route.query.forwardRoute,
					bookAppointment: !route.query.bookAppointment || route.query.bookAppointment === "true",
					hideHeader: route.query.hideHeader === "true",
				},
				route.params);
		},
		component: AqsAppointmentType,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{ // dummy redirect route. This route redirects the user to the aqs booking for an organization's primary clinic
		path: "/aqs/book/org_aqs_redirect",
		name: AqsBookingRoutes.OrganizationRedirect,
		component: OrgAqsRedirect,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/aqs/book/live-chat",
		name: AqsBookingRoutes.LiveChat,
		component: LiveChatBooking,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
