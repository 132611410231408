import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-723d1f50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-chooser-wrapper" }
const _hoisted_2 = ["name", "accept", "multiple", "disabled", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "file",
      name: _ctx.name,
      ref: "fileChooser",
      class: "file-chooser-input",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileListChange($event.target.name, $event.target.files))),
      accept: _ctx.accept,
      multiple: _ctx.multiple,
      disabled: _ctx.disabled,
      "aria-label": _ctx.ariaLabel
    }, null, 40, _hoisted_2),
    (_ctx.showButton)
      ? (_openBlock(), _createBlock(_component_BaseButton, _mergeProps({ key: 0 }, _ctx.buttonProps, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pickNewFiles())),
          "aria-label": "this.$mhat('AttachmentSelector.PickNewFiles')"
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 16))
      : _createCommentVNode("", true)
  ]))
}