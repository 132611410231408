
	import {Options, Prop, Vue} from "vue-property-decorator";
	import { IonButton, IonIcon } from "@ionic/vue";
	import { star, starOutline } from "ionicons/icons";

	@Options({components: {IonButton, IonIcon}})
	export default class Star extends Vue
	{
		@Prop({type: Boolean}) filled: boolean;

		STAR_FILLED = star;
		STAR_OUTLINE = starOutline;
	}

