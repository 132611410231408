import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import DeviceInfo from "@/lib/DeviceInfo";
import TelehealthTextChat from "@/views/patient_user/appointment/TelehealthTextChat.vue";
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import AuthStore from "@/lib/vuex/auth.store";
import TelehealthLiveChat from "@/views/patient_user/appointment/TelehealthLiveChat.vue";

const AppointmentsHome = () => import(
	/* webpackChunkName: "Appointments" */ "@/views/patient_user/appointment/AppointmentsHome.vue");
const TelehealthPatient = () => import(
	/* webpackChunkName: "TelehealthPatient" */ "@/views/patient_user/appointment/Telehealth.vue");
const AppointmentConfirmation = () => import(
	/* webpackChunkName: "AppointmentConfirmation" */ "@/views/patient_user/appointment/AppointmentConfirmation.vue");
const TelehealthPatientIos = () => import(
	/* webpackChunkName: "TelehealthPatient" */ "@/views/patient_user/appointment/TelehealthPatientIos.vue");

export enum AppointmentsRoute
{
	Home = "AppointmentHome",
	View = "AppointmentView",
	Telehealth = "AppointmentTelehealth",
	TelehealthAqs = "AppointmentTelehealthAqs",
	TelehealthTextChat = "TelehealthTextChat",
	TelehealthTextChatAqs = "TelehealthTextChatAqs",
	TelehealthLiveChat = "TelehealthLiveChat",
}

export const appointmentRoutes: RouteRecordRaw[] = [
	{
		path: "/appointments/patient/:selectedPatientId?/",
		alias: "/appointments/:selectedPatientId?",
		name: AppointmentsRoute.Home,
		props: (route) =>
		{
			if (!route.params.selectedPatientId || route.params.selectedPatientId.length === 0)
			{
				delete route.params.selectedPatientId;
				return Object.assign({selectedPatientId: AuthStore.loggedInPatient.id}, route.params);
			}
			return route.params;
		},
		component: AppointmentsHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/appointments/patient/:selectedPatientId?/clinic/:clinicId?/remoteAppointment/:remoteAppointmentId?",
		name: AppointmentsRoute.View,
		props: (route) =>
		{
			if (!route.params.selectedPatientId || route.params.selectedPatientId.length === 0)
			{
				delete route.params.selectedPatientId;
				return Object.assign({selectedPatientId: AuthStore.loggedInPatient.id}, route.params);
			}
			return route.params;
		},
		component: AppointmentsHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/appointments/:appointmentId/session",
		name: AppointmentsRoute.Telehealth,
		component: DeviceInfo.isIOS() ? TelehealthPatientIos : TelehealthPatient,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/aqs/clinic/:clinicId/queue/:queueId/queued_appointment/:queuedAppointmentId/session",
		name: AppointmentsRoute.TelehealthAqs,
		props: (route) =>
		{
			return Object.assign({telehealthMode: TELEHEALTH_MODE.AQS}, route.params);
		},
		component: DeviceInfo.isIOS() ? TelehealthPatientIos : TelehealthPatient,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/appointments/:appointmentId/session/chat",
		name: AppointmentsRoute.TelehealthTextChat,
		props: true,
		component: TelehealthTextChat,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/aqs/clinic/:clinicId/queue/:queueId/queued_appointment/:queuedAppointmentId/session/chat",
		name: AppointmentsRoute.TelehealthTextChatAqs,
		component: TelehealthTextChat,
		props: (route) =>
		{
			return Object.assign({telehealthMode: TELEHEALTH_MODE.AQS}, route.params);
		},
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/aqs/clinic/:clinicId/queue/:queueId/queued_appointment/:queuedAppointmentId/session/live-chat",
		name: AppointmentsRoute.TelehealthLiveChat,
		props: (route) =>
		{
			return Object.assign({telehealthMode: TELEHEALTH_MODE.AQS}, route.params);
		},
		component: TelehealthLiveChat,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
