

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import DeviceInfo from "@/lib/DeviceInfo";

	@Options({})
	export default class FileChooser extends Vue
	{
		@Prop({type: String, required: true}) name: string;
		@Prop({type: Boolean, default: true}) multiple: boolean;
		@Prop({type: Boolean, default: true}) showButton: boolean;
		@Prop({type: Object}) buttonProps: any;
		@Prop({type: Boolean}) disabled: boolean;
		@Prop({type: String}) accept: string;
		@Prop({type: String, default: null}) ariaLabel: string;

		private MB = 1000000;
		private maxSizeMB = 25;

		public pickNewFiles()
		{
			const inputElem: HTMLElement = this.$refs.fileChooser as HTMLElement;
			inputElem.click();
		}

		public async onFileListChange(fieldName, fileList)
		{
			const clonedFileList = [];

			for (const file of fileList)
			{
				const clonedFile = await this.cloneFile(file);

				// vue will run out of memory if the attachment is too large,
				// do a fast size check to prevent this.
				if (clonedFile.size > this.maxSizeMB * this.MB)
				{
					WebNotification.$emit({
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("FileChooser.GenericAttachmentErrorTitle"),
						message: this.$mhat("FileChooser.MaxFileSizeErrorMessage",
							{fileName: clonedFile.name, maxFileSize: this.maxSizeMB}),
					});
					return;
				}

				clonedFileList.push(clonedFile);
			}

			this.$emit("chooseFiles", fieldName, clonedFileList);
		}

		public async cloneFile(file)
		{
			if (DeviceInfo.isAndroid())
			{
				// Fixes a current bug with Android (https://bugs.chromium.org/p/chromium/issues/detail?id=1063576)
				const buffer = await file.arrayBuffer();
				const fileClone = new File([buffer], file.name, { type: file.type });

				return fileClone;
			}
			else
			{
				return file;
			}
		}
	}
