import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dc46448"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon icon-chevron-down m-l-8"
}
const _hoisted_2 = {
  key: 1,
  class: "icon icon-chevron-up m-l-8"
}
const _hoisted_3 = {
  key: 2,
  class: "chat-message d-flex justify-content-center"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 3,
  class: "chat-event"
}
const _hoisted_6 = { class: "event-time" }
const _hoisted_7 = { class: "event-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentPreview = _resolveComponent("DocumentPreview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat-row", {active: _ctx.active, isLast: _ctx.isLast, [_ctx.originClass]: true}])
  }, [
    (_ctx.isMessageItem)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["chat-message", _ctx.originClass]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.item.message), 1)
        ], 2))
      : (_ctx.isDocumentItem && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["document-message", _ctx.originClass])
          }, [
            _createElementVNode("div", {
              class: "document-label font-color-neutral-grey d-flex flex-row align-items-center",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
            }, [
              _createTextVNode(_toDisplayString(_ctx.document.name) + " ", 1),
              (_ctx.expanded)
                ? (_openBlock(), _createElementBlock("i", _hoisted_1))
                : (_openBlock(), _createElementBlock("i", _hoisted_2))
            ]),
            _createVNode(_component_DocumentPreview, {
              class: "document-preview",
              style: _normalizeStyle({'display': _ctx.expanded ? 'inline-block' : 'none'}),
              document: _ctx.document,
              onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('previewLoaded'))),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click')))
            }, null, 8, ["style", "document"])
          ], 2))
        : (_ctx.isTypingItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "typing",
                src: _ctx.MoreIcon,
                alt: "Contact is typing"
              }, null, 8, _hoisted_4)
            ]))
          : (_ctx.isEventItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.chatItemTime), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.message), 1)
              ]))
            : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["received-at", {active: _ctx.active}])
    }, _toDisplayString(_ctx.receivedAtTime), 3)
  ], 2))
}