import moment, {Moment} from "moment";
import {AppointmentTransfer, ClinicAppointmentType} from "@/open_api/generated";

export enum DAY_PERIOD
{
	MORNING,
	AFTERNOON,
	EVENING,
}

interface DayPeriodThreshold
{
	startTime: Moment;
	endTime: Moment;
}

export class Appointment
{
	public static getDayPeriod(date: string, startTime: string): DAY_PERIOD
	{
		return this.createDayPeriod(moment(`${date}T${startTime}`));
	}

	public static createDayPeriod(startDateTime: Moment): DAY_PERIOD
	{
		const morning: DayPeriodThreshold = getPeriodThreshold(startDateTime, DAY_PERIOD.MORNING);
		const afternoon: DayPeriodThreshold = getPeriodThreshold(startDateTime, DAY_PERIOD.AFTERNOON);
		const evening: DayPeriodThreshold = getPeriodThreshold(startDateTime, DAY_PERIOD.EVENING);

		if (startDateTime >= morning.startTime && startDateTime <= morning.endTime)
		{
			return DAY_PERIOD.MORNING;
		}

		if (startDateTime >= afternoon.startTime && startDateTime <= afternoon.endTime)
		{
			return DAY_PERIOD.AFTERNOON;
		}

		if (startDateTime >= evening.startTime && startDateTime <= evening.endTime)
		{
			return DAY_PERIOD.EVENING;
		}
	}

	private _id: string;
	private _patientId: string;
	private _clinicId: string;
	private _providerId: string;

	private _startDateTime: Moment;
	private _endDateTime: Moment;
	private _currentClinicTime: Moment;
	private _reason: string;
	private _location: string;
	private _notes: string;
	private _bookedByPatient: boolean;
	private _appointmentType: ClinicAppointmentType;
	private _canCancel: boolean;
	private _isCancelled: boolean;
	private _remoteId: string;
	private _remotePatientId: string;
	private _remoteProviderId: string;
	private _providerName: string;
	private _clinicName: string;
	private _type: string;

	private _patientFirstName: string;
	private _patientLastName: string;

	private _dayPeriod: DAY_PERIOD;
	private _remoteTemplateId: string;
	private _maxBookingDurationMinutes: number;
	private _durationMinutes: number;
	private _slotStartTime: string;
	private _isVirtual: boolean;

	private _eChartLink: URL;
	private _masterRecordLink: URL;
	private _rxLink: URL;
	private _billingLink: URL;

	constructor(appointmentTO: AppointmentTransfer)
	{
		this.id = appointmentTO.appointment_id;
		this.patientId = appointmentTO.patient_id;
		this.clinicId = appointmentTO.clinic_id;
		this.providerId = appointmentTO.provider_id;
		this.startDateTime = moment(appointmentTO.start_datetime_utc);
		this.endDateTime = moment(appointmentTO.end_datetime_utc);
		this.dayPeriod = Appointment.createDayPeriod(this.startDateTime);
		this.reason = appointmentTO.reason;
		this.location = appointmentTO.location;
		this.notes = appointmentTO.notes;
		this.bookedByPatient = appointmentTO.booked_by_patient;
		this.appointmentType = appointmentTO.appointment_type;
		this.canCancel = appointmentTO.can_cancel;
		this.isCancelled = appointmentTO.is_cancelled;
		this.remoteId = appointmentTO.remote_id;
		this.remotePatientId = appointmentTO.remote_patient_id;
		this.remoteProviderId = appointmentTO.remote_provider_id;
		this.providerName = appointmentTO.provider_name;
		this.clinicName = appointmentTO.clinic_name;
		this.type = appointmentTO.type;
		this.durationMinutes = appointmentTO.duration_minutes;
		this.currentClinicTime = moment.utc(appointmentTO.current_clinic_time);
		this.isVirtual = appointmentTO.is_virtual;
		this.patientFirstName = appointmentTO.patient_first_name;
		this.patientLastName = appointmentTO.patient_last_name;
		if (appointmentTO.links)
		{
			this.eChartLink = new URL(appointmentTO.links.e_chart);
			this.masterRecordLink = new URL(appointmentTO.links.master_record);
			this.rxLink = new URL(appointmentTO.links.r_x);
			this.billingLink = new URL(appointmentTO.links.billing);
		}
	}

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	/**
	 * Get a unique id string built out of various id's in the appointment.
	 * @return the composite id.
	 */
	get compositeId(): string
	{
		return `${this.id}${this.remoteId}${this.clinicId}`;
	}

	get patientId(): string
	{
		return this._patientId;
	}

	set patientId(value: string)
	{
		this._patientId = value;
	}

	get clinicId(): string
	{
		return this._clinicId;
	}

	set clinicId(value: string)
	{
		this._clinicId = value;
	}

	get providerId(): string
	{
		return this._providerId;
	}

	set providerId(value: string)
	{
		this._providerId = value;
	}

	get startDateTime(): moment.Moment
	{
		return this._startDateTime;
	}

	set startDateTime(value: moment.Moment)
	{
		this._startDateTime = value;
	}

	get endDateTime(): moment.Moment
	{
		return this._endDateTime;
	}

	set endDateTime(value: moment.Moment)
	{
		this._endDateTime = value;
	}

	get dayPeriod(): DAY_PERIOD
	{
		return this._dayPeriod;
	}

	set dayPeriod(value: DAY_PERIOD)
	{
		this._dayPeriod = value;
	}

	get remoteTemplateId(): string
	{
		return this._remoteTemplateId;
	}

	set remoteTemplateId(value: string)
	{
		this._remoteTemplateId = value;
	}

	get maxBookingDurationMinutes(): number
	{
		return this._maxBookingDurationMinutes;
	}

	set maxBookingDurationMinutes(value: number)
	{
		this._maxBookingDurationMinutes = value;
	}

	get durationMinutes(): number
	{
		return this._durationMinutes;
	}

	set durationMinutes(value: number)
	{
		this._durationMinutes = value;
	}

	get reason(): string
	{
		return this._reason;
	}

	set reason(value: string)
	{
		this._reason = value;
	}

	get slotStartTime(): string
	{
		return this._slotStartTime;
	}

	set slotStartTime(value: string)
	{
		this._slotStartTime = value;
	}

	get isVirtual(): boolean
	{
		return this._isVirtual;
	}

	set isVirtual(value: boolean)
	{
		this._isVirtual = value;
	}

	get location(): string
	{
		return this._location;
	}

	set location(value: string)
	{
		this._location = value;
	}

	get notes(): string
	{
		return this._notes;
	}

	set notes(value: string)
	{
		this._notes = value;
	}

	get bookedByPatient(): boolean
	{
		return this._bookedByPatient;
	}

	set bookedByPatient(value: boolean)
	{
		this._bookedByPatient = value;
	}

	get appointmentType(): ClinicAppointmentType
	{
		return this._appointmentType;
	}

	get isChat(): boolean
	{
		return this._appointmentType === ClinicAppointmentType.Chat ||
			this._appointmentType === ClinicAppointmentType.LiveChat;
	}

	set appointmentType(value: ClinicAppointmentType)
	{
		this._appointmentType = value;
	}

	get canCancel(): boolean
	{
		return this._canCancel;
	}

	set canCancel(value: boolean)
	{
		this._canCancel = value;
	}

	get isCancelled(): boolean
	{
		return this._isCancelled;
	}

	set isCancelled(value: boolean)
	{
		this._isCancelled = value;
	}

	get remoteId(): string
	{
		return this._remoteId;
	}

	set remoteId(value: string)
	{
		this._remoteId = value;
	}

	get remotePatientId(): string
	{
		return this._remotePatientId;
	}

	set remotePatientId(value: string)
	{
		this._remotePatientId = value;
	}

	get remoteProviderId(): string
	{
		return this._remoteProviderId;
	}

	set remoteProviderId(value: string)
	{
		this._remoteProviderId = value;
	}

	get providerName(): string
	{
		return this._providerName;
	}

	set providerName(value: string)
	{
		this._providerName = value;
	}

	get clinicName(): string
	{
		return this._clinicName;
	}

	set clinicName(value: string)
	{
		this._clinicName = value;
	}

	get type(): string
	{
		return this._type;
	}

	set type(value: string)
	{
		this._type = value;
	}

	get currentClinicTime(): moment.Moment
	{
		return this._currentClinicTime;
	}

	set currentClinicTime(value: moment.Moment)
	{
		this._currentClinicTime = value;
	}

	get patientFirstName(): string
	{
		return this._patientFirstName;
	}

	set patientFirstName(value: string)
	{
		this._patientFirstName = value;
	}

	get patientLastName(): string
	{
		return this._patientLastName;
	}

	set patientLastName(value: string)
	{
		this._patientLastName = value;
	}

	get eChartLink(): URL
	{
		return this._eChartLink;
	}

	set eChartLink(value: URL)
	{
		this._eChartLink = value;
	}

	get masterRecordLink(): URL
	{
		return this._masterRecordLink;
	}

	set masterRecordLink(value: URL)
	{
		this._masterRecordLink = value;
	}

	get rxLink(): URL
	{
		return this._rxLink;
	}

	set rxLink(value: URL)
	{
		this._rxLink = value;
	}

	get billingLink(): URL
	{
		return this._billingLink;
	}

	set billingLink(value: URL)
	{
		this._billingLink = value;
	}
}

function getPeriodThreshold(appointmentDate, dayPeriod: DAY_PERIOD): DayPeriodThreshold
{
	const MORNING_END = {hour: 11, minute: 59, second: 59};
	const AFTERNOON_START = {hour: 12, minute: 0, second: 0};
	const AFTERNOON_END = {hour: 16, minute: 59, second: 59};
	const EVENING_START = {hour: 17, minute: 0, second: 0};

	const startTime = appointmentDate.clone();
	const endTime = appointmentDate.clone();

	switch (dayPeriod)
	{
		case DAY_PERIOD.MORNING:
			return {
				startTime: startTime.startOf("day"),
				endTime: endTime.set(MORNING_END),
			};

		case DAY_PERIOD.AFTERNOON:
			return {
				startTime: startTime.set(AFTERNOON_START),
				endTime: endTime.set(AFTERNOON_END),
			};

		case DAY_PERIOD.EVENING:
			return {
				startTime: startTime.set(EVENING_START),
				endTime: endTime.endOf("day"),
			};
	}
}
