import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78947b06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "text header appt-booked-text" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 0,
  class: "line-position-indicator"
}
const _hoisted_5 = { class: "text subheader" }
const _hoisted_6 = { class: "text line-position" }
const _hoisted_7 = { class: "text subheader" }
const _hoisted_8 = {
  key: 1,
  class: "line-position-indicator"
}
const _hoisted_9 = { class: "text line-position-text" }
const _hoisted_10 = { class: "text" }
const _hoisted_11 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhitelabelPrimaryLogo = _resolveComponent("WhitelabelPrimaryLogo")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_content_wrapper = _resolveComponent("content-wrapper")!

  return (_openBlock(), _createBlock(_component_content_wrapper, {
    contentWidthMid: "",
    contentBodyBottomMarginDisabled: ""
  }, _createSlots({
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_WhitelabelPrimaryLogo, { class: "logo-constraints" }),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.AppointmentBookedHeader")), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.AppointmentBookedSubheader")), 1),
        (_ctx.queuePosition > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.QueuePositionTextPart1")), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.queuePositionText), 1),
              _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.QueuePositionTextPart2")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.NextInLineText")), 1)
            ])),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.CancelAppointmentInfoText")), 1),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_BaseButton, {
            color: _ctx.ButtonColor.DEFAULT,
            pattern: _ctx.ButtonColorPattern.DEFAULT,
            size: _ctx.ButtonSize.DEFAULT,
            disabled: _ctx.loadingQueue.isLoading,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancel()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$mhat("OnDemandBookingWaitingRoom.Cancel")), 1)
            ]),
            _: 1
          }, 8, ["color", "pattern", "size", "disabled"])
        ])
      ])
    ]),
    _: 2
  }, [
    (_ctx.showFooter)
      ? {
          name: "content-footer",
          fn: _withCtx(() => [
            _createVNode(_component_CorporateFooter, {
              branded: "",
              "brand-white-bg": "",
              "show-support-link": false
            })
          ])
        }
      : undefined
  ]), 1024))
}